import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { catchError, mergeMap, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { LeadCallActions } from './lead-call.actions';
import {
  Firestore,
  doc,
  onSnapshot,
  setDoc,
} from '@angular/fire/firestore';
import { Action } from '@ngrx/store';
import { DialerLeadCall } from 'src/app/shared/models';
import { ElasticService } from 'src/app/shared/services/elastic.service';
import { Consumer, consumer_Dialer_ResultCategoryToJSON, consumer_Dialer_ResultToJSON } from 'src/app/shared/models/classes/consumer';
import { DateContainer, EnrollmentCode } from 'src/app/shared/models/classes/misc';

@Injectable()
export class LeadCallEffects {
  loadLeadCalls$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadCallActions.loadLeadCalls),
      mergeMap(({ agencyId, leadId }) => {
        return new Observable<Action>((subscriber) => {
          const query: any = {
            bool: {
              must: [,
                {
                  match: {
                    'agency.id': agencyId,
                  },
                },
              ],
            },
          }

          if (leadId) {
            query.bool.must.push({
              match: {
                'lead.id': leadId,
              },
            })
          }

          this.elasticService
            .query('lead-calls', {
              size: 1000,
              // _source: {
              //   exclude: ["logs"]
              // },
              sort: {
                'createdAt': {
                  order: 'desc'
                }
              },
              query: query
            })
            .then((res) => {
              const leadCalls = res.hits.hits.map((hit: any) =>
                DialerLeadCall.fromJSON({ ...hit._source, _id: hit._id }),
              );
              subscriber.next(LeadCallActions.loadLeadCallsSuccess({ leadCalls }));
            })
            .catch((error) => {
              subscriber.next(LeadCallActions.loadLeadCallsFailure({ error }));
            });
        }).pipe(
          catchError((error) =>
            of({ type: '[LeadCall API] Load LeadCalls Error', error }),
          ),
        );
      }),
    );
  });

  loadLeadCall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadCallActions.loadLeadCall),
      mergeMap(({ agencyId, leadId, leadCallId }) => {
        return new Observable<Action>((subscriber) => {
          const query = {
            bool: {
              must: [,
                {
                  match: {
                    'agency.id': agencyId,
                  },
                },
                {
                  match: {
                    'lead.id': leadId,
                  },
                },
                {
                  bool: {
                    should: [
                      {
                        match: {
                          '_id': `${agencyId}_${leadId}_${leadCallId}`,
                        },
                      },
                      {
                        match: {
                          'id': leadCallId,
                        },
                      },
                      // compatibility with old calls before 2024-07-21
                      {
                        match: {
                          '_id': leadCallId,
                        },
                      },
                    ]
                  }
                }
              ],
            },
          }

          this.elasticService
            .query('lead-calls', {
              size: 1,
              query: query
            })
            .then((res) => {
              const hit = res.hits.hits?.[0]
              console.log('HIT', hit)

              let id = hit._source.id
              if (!id) {
                const splitted: string[] = hit._id.split('_')
                splitted.shift()
                splitted.shift()

                id = splitted.join('_')

              }

              const leadCall = DialerLeadCall.fromJSON({ ...hit._source, _id: hit._id })

              subscriber.next(LeadCallActions.loadLeadCallSuccess({ leadCall }));
            })
            .catch((error) => {
              subscriber.next(LeadCallActions.loadLeadCallFailure({ error }));
            });
        });
      }),
    );
  });

  loadLeadCallLive$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadCallActions.loadLeadCallLive),
      mergeMap(({ agencyId, leadId, leadCallId }) => {
        return new Observable<Action>((subscriber) => {
          const unsubscribe = onSnapshot(
            doc(this.firestore, 'agencies', agencyId, 'leads', leadId, 'calls', leadCallId),
            (snapshot) => {
              const leadCall = DialerLeadCall.fromJSON({
                ...snapshot.data(),
                id: snapshot.id,
                lead: {
                  id: leadId
                },
                agency: {
                  id: agencyId
                }
              });
              subscriber.next(LeadCallActions.loadLeadCallLiveSuccess({ leadCall }));
            },
            (error) => {
              subscriber.next(LeadCallActions.loadLeadCallLiveFailure({ error }));
            },
          );
          return unsubscribe
        }).pipe(
          catchError((error) =>
            of({ type: '[Lead API] Load Leads Live Error', error }),
          ),
        );
      }),
    );
  });

  // addLeadCall$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LeadCallActions.addLeadCall),
  //     mergeMap(async ({ leadcall }) => {
  //       try {
  //         const docRef = await addDoc(
  //           collection(this.firestore, 'leadcalls'),
  //           leadcall,
  //         );
  //         return LeadCallActions.addLeadCallSuccess({
  //           leadcall: DialerLeadCall.fromJSON({ ...leadcall, id: docRef.id }),
  //         }); // return new leadcall with id
  //       } catch (error) {
  //         return LeadCallActions.addLeadCallFailure({ error });
  //       }
  //     }),
  //   );
  // });

  // removeLeadCall$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(LeadCallActions.removeLeadCall),
  //     mergeMap(async ({ leadcallId }) => {
  //       try {
  //         await deleteDoc(doc(this.firestore, 'leadcalls', leadcallId));
  //         return LeadCallActions.removeLeadCallSuccess({ leadcallId }); // return removed leadcall's id
  //       } catch (error) {
  //         return LeadCallActions.removeLeadCallFailure({ error });
  //       }
  //     }),
  //   );
  // });

  updateLeadCall$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LeadCallActions.updateLeadCall),
      mergeMap(async ({ agencyId, leadId, leadCallId, leadCall }) => {
        console.log('updateLeadCallAction', { agencyId, leadId, leadCallId, leadCall });
        try {
          let updateDoc: any = {}

          if (leadCall.result) {
            updateDoc.result = consumer_Dialer_ResultToJSON(leadCall.result)
          }

          if (leadCall.resultCategory) {
            updateDoc.resultCategory = consumer_Dialer_ResultCategoryToJSON(leadCall.resultCategory)
          }

          if (leadCall.enrollmentCode) {
            updateDoc.enrollmentCode = EnrollmentCode.toJSON(leadCall.enrollmentCode)
          }

          if (leadCall.lead) {
            const leadJSON = Consumer.toJSON(leadCall.lead) as { [key in keyof Consumer]: unknown }

            if (leadJSON) {
              updateDoc.lead = {
                firstName: leadJSON.firstName,
                lastName: leadJSON.lastName,
                email: leadJSON.email,
                birthDate: leadJSON.birthDate
              }

              if (leadJSON.address) {
                updateDoc.lead.address = leadJSON.address
              }

            }
          }



          if (Object.keys(updateDoc).length > 0) {
            await setDoc(
              doc(this.firestore, 'agencies', agencyId, 'leads', leadId, 'calls', leadCallId),
              updateDoc,
              {
                merge: true,
              }
            );
          }

          console.log('updateLeadCallSuccess', leadCallId, leadCall)
          return LeadCallActions.updateLeadCallSuccess({
            agencyId,
            leadId,
            leadCallId,
            leadCall,
          });
        } catch (error) {
          console.error('updateLeadCallError', error)
          return LeadCallActions.updateLeadCallFailure({ error });
        }
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private elasticService: ElasticService,
    private firestore: Firestore,
  ) { }
}
