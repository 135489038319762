import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Inject, NgZone, PLATFORM_ID, Renderer2, signal, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import pack from '../../package.json';
import { ToastService } from './shared/services/toast.service';
import { AuthService } from './shared/services/auth.service';
import { faHeadset, faLock, faPaperPlane, faTurnLeft, faWifiExclamation } from '@fortawesome/pro-regular-svg-icons';
import { SuperviseDataService } from './shared/services/supervise/supervise-data.service';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe, isPlatformServer } from '@angular/common';
import { CheckUpdateService } from './shared/services/update/check-update.service';
import { Loader } from '@googlemaps/js-api-loader';
import { AudioService } from './shared/services/audio/audio.service';

@Component({
  imports: [AsyncPipe, FaIconComponent, RouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit {
  @ViewChild('localAudio') localAudio!: ElementRef;
  @ViewChild('remoteAudio') remoteAudio!: ElementRef;

  @ViewChild('routerOutlet') routerOutlet!: RouterOutlet;

  title = 'enrollhere-dialer';

  faLock = faLock
  faPaperPlane = faPaperPlane
  faTurnLeft = faTurnLeft
  faWifiExclamation = faWifiExclamation
  faHeadset = faHeadset

  isOffline = signal(false)

  version = pack.version;

  //do not remove checkUpdateService as it then stops to update automatically
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    public audioService: AudioService,
    public authService: AuthService,
    private ngZone: NgZone,
    private renderer: Renderer2,
    private superviseService: SuperviseDataService,
    private toastService: ToastService,
    public updateService: CheckUpdateService,
  ) { }

  ngAfterViewInit() {
    this.loadMaps();
    this.authService.init()
    this.audioService.initializeAudio()

    this.superviseService.localAudio = this.localAudio
    this.superviseService.remoteAudio = this.remoteAudio

    console.log('localAudio Call Status', this.localAudio)
    console.log('remoteAudio Call Status', this.remoteAudio)

    // Check query params again and initialize supervise service if needed
    const queryParams = this.parseQueryParams(window.location.href);
    if (queryParams['hm'] !== 'true') {
      console.log('Initializing supervise service');
      this.superviseService.initialize();
    }


    this.ngZone.runOutsideAngular(() => {

      window.addEventListener("online", async (e) => {
        console.log("online");
        if (this.isOffline()) {
          this.toastService.success("Internet connection recovered 😍");
        }
        this.isOffline.set(false)
      });
      window.addEventListener("offline", async (e) => {
        console.log("offline");
        this.toastService.error(
          "Internet connection lost 😢",
          "Please connect to the internet to continue"
        );

        this.isOffline.set(true)
      });

      window.addEventListener("unload", async (e) => {
        console.log("unload");
      })

      // window.addEventListener("pagehide", async (e) => {
      //   console.log("pagehide");
      // })

      // window.addEventListener("pageshow", async (e) => {
      //   console.log("pageshow");
      // })

      window.addEventListener("freeze", async (e) => {
        console.log("freeze");
      })

      window.addEventListener("resume", async (e) => {
        console.log("resume");
      })
      // window.addEventListener("visibilitychange", async (e) => {
      //   console.log("visibilitychange", document.visibilityState);
      // })

    })

    this.loadHeapScript(environment.heapEnvId)
  }


  loadMaps() {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    try {
      const loader = new Loader({
        apiKey: environment.googleMapsApiKey,
        version: 'weekly',
        libraries: ['places']
      });

      loader.importLibrary('core').then(async (google) => {
        console.log('google maps loaded', google);
      }).catch(e => {
        console.warn('Error loading google maps', e)
      })
      loader.importLibrary('places').then(async (places) => {
        console.log('places library loaded', places);
      }).catch(e => {
        console.warn('Error loading places library', e)
      })
    } catch (e) {
      console.warn('Error loading maps', e)
    }
  }

  moveHere() {
    this.authService.moveSessionHere()
  }

  backToEH() {
    window.location.href = environment.enrollhereBaseUrl
  }

  private parseQueryParams(url: string): { [key: string]: string } {
    const queryParams: { [key: string]: string } = {};
    const queryString = url.split('?')[1];
    if (queryString) {
      const pairs = queryString.split('&');
      for (const pair of pairs) {
        const [key, value] = pair.split('=');
        queryParams[decodeURIComponent(key)] = decodeURIComponent(value || '');
      }
    }
    return queryParams;
  }


  private loadHeapScript(envId: string): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      window.heapReadyCb = window.heapReadyCb || [];
      window.heap = window.heap || [];
      heap.load = function(e, t) {
        window.heap.envId = e;
        window.heap.clientConfig = t = t || {};
        window.heap.clientConfig.shouldFetchServerConfig = !1;
        var a = document.createElement("script");
        a.type = "text/javascript";
        a.async = !0;
        a.src = "https://cdn.us.heap-api.com/config/" + e + "/heap_config.js";
        var r = document.getElementsByTagName("script")[0];
        r.parentNode.insertBefore(a, r);
        var n = [
          "init", "startTracking", "stopTracking", "track", "resetIdentity", "identify", "getSessionId", "getUserId", "getIdentity",
          "addUserProperties", "addEventProperties", "removeEventProperty", "clearEventProperties", "addAccountProperties",
          "addAdapter", "addTransformer", "addTransformerFn", "onReady", "addPageviewProperties", "removePageviewProperty",
          "clearPageviewProperties", "trackPageview"
        ];
        var i = function(e) {
          return function() {
            var t = Array.prototype.slice.call(arguments, 0);
            window.heapReadyCb.push({
              name: e,
              fn: function() {
                heap[e] && heap[e].apply(heap, t);
              }
            });
          };
        };
        for (var p = 0; p < n.length; p++) heap[n[p]] = i(n[p]);
      };
      heap.load("${envId}", { disableTextCapture: true });
    `;
    this.renderer.appendChild(document.body, script);
  }
}
